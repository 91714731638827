import React, { useState } from 'react';
import { string } from 'prop-types';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  Heading,
  useToast,
} from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import useMutation from '@/hooks/useMutation';
import useGuestInfo from '@/hooks/useGuestInfo';
import usePartner from '@hooks/usePartner';

import { API_HOSTNAME, API_RSVP_TABLE } from '@/constants';
import { Title, txtForm, txtTitle } from './locales';
import {
  TYPE,
  ERROR_TYPE,
  INPUT_COMMON_PROPS,
  SELECT_COMMON_PROPS,
  FORM_LABEL_PROPS,
} from './types';
import { BTN_COLOR, DEFAULT_BUTTON_PROPS, DEFAULT_OPTIONS_PROPS } from '@/constants/colors';
import { ENABLE_GUEST_PERSONALIZATION, ENABLE_PARTNER_MODE } from '@/constants/feature-flags';
import BorderFrame from '@components/Common/BorderFrame';

const enc = (str) => encodeURIComponent(str);

/**
 * function to render RSVP component
 * @returns {JSX.Element}
 * @author idindrakusuma
 */
function RSVPSection({ lang, ...rest }) {
  const toast = useToast();
  const { name: nameByURL } = useGuestInfo();
  const totalPartner = usePartner();

  const [onSentConfirmation, loading] = useMutation(API_HOSTNAME, API_RSVP_TABLE, 'insert');

  const [name, setName] = useState(() => nameByURL || '');
  const [phone, setPhone] = useState('-');
  const [address, setAddress] = useState('-');
  const [attended, setAttended] = useState(TYPE.YES);
  const [bringPartner, setBringPartner] = useState(1);
  const [errorType, setErrorType] = useState(ERROR_TYPE);

  /**
   * function to set state
   * @param {object} e - html event value
   * @param {function} setState
   * @param {string} typeState
   * @returns
   */
  const onSetState = (e, setState, typeState) => {
    const value = e.target.value;
    setState(value);

    if (typeState === 'email') {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        setErrorType({ ...errorType, email: 'Invalid email address' });
        return;
      }
    }

    if (typeState === 'phone') {
      if (!/\d+/.test(value) || value.length > 13) {
        setErrorType({ ...errorType, phone: 'Invalid phone number' });
        return;
      }
    }

    setErrorType(ERROR_TYPE);
  };

  /**
   * function to submit to BE with check the form value first
   */
  const onSubmitForm = async () => {
    if (errorType.name || errorType.phone || errorType.address) return;

    if (!name || !phone || !address) {
      setErrorType({
        name: !name ? txtForm.required[lang] : '',
        phone: !phone ? txtForm.required[lang] : '',
        address: !address ? txtForm.required[lang] : '',
      });

      return;
    }

    const query =
      `nama=${enc(name)}` +
      `&hp=${phone}` +
      `&hadir=${attended}` +
      `&jumlah_tamu=${attended === TYPE.NO ? '' : bringPartner}` +
      `&alamat=${enc(address)}`;

    const result = await onSentConfirmation(query);

    if (result.success) {
      toast({
        title: txtForm.success[lang],
        description: txtForm.msgSuccess[lang],
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setName('');
      setPhone('');
      setAddress('-');
    } else {
      toast({
        title: 'Oops!',
        description: txtForm.msgError[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box padding="0 0 42px 0" bgColor="bgPrimary" {...rest}>
      <WithAnimation>
        <Box padding="24px" bgColor="bgPrimary" borderRadius="2xl" borderTopRadius="0">
          <BorderFrame isHeader h="42px" width="calc(100% - 48px)" left="24px" />
          <Box padding="42px 24px" bgRepeat="no-repeat" width="100%" bgSize="100% 100%">
            <BorderFrame
              height="calc(100% - 132px)"
              width="calc(100% - 48px)"
              left="24px"
              top="66px"
            />
            <Box width="100%">
              {/* Title & Desc Section */}
              <Box textAlign="center" color="mainColorText" pos={'relative'}>
                <Heading fontWeight="normal" size="3xl" marginTop="16px">
                  {Title[lang]}
                </Heading>
                <Text fontSize="md" margin="16px 0 24px 0">
                  {txtTitle[lang]}
                </Text>
              </Box>
              {/* Form Sections - Name */}
              <FormControl margin="8px 0" isInvalid={errorType.name}>
                <FormLabel {...FORM_LABEL_PROPS}>{txtForm.name[lang]}:</FormLabel>
                <Input
                  {...INPUT_COMMON_PROPS}
                  placeholder="..."
                  value={name}
                  onChange={(e) => onSetState(e, setName)}
                />
                <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
              </FormControl>
              {/* Form Sections - Phone Number */}
              {false && (
                <FormControl isInvalid={errorType.phone}>
                  <FormLabel {...FORM_LABEL_PROPS}>{txtForm.hp[lang]}:</FormLabel>
                  <Input
                    {...INPUT_COMMON_PROPS}
                    placeholder="..."
                    value={phone}
                    onChange={(e) => onSetState(e, setPhone, 'phone')}
                  />
                  <FormErrorMessage marginTop="4px">{errorType.phone}</FormErrorMessage>
                </FormControl>
              )}
              {/* Form Sections - Address */}

              <FormControl isInvalid={errorType.address}>
                <FormLabel {...FORM_LABEL_PROPS}>{txtForm.address[lang]}:</FormLabel>
                <Input
                  {...INPUT_COMMON_PROPS}
                  placeholder="..."
                  value={address}
                  onChange={(e) => onSetState(e, setAddress)}
                />
                <FormErrorMessage marginTop="4px">{errorType.address}</FormErrorMessage>
              </FormControl>

              {/* Form Sections - Attendance */}
              <FormControl margin="8px 0">
                <FormLabel {...FORM_LABEL_PROPS}>{txtForm.willYoutAttend[lang]}</FormLabel>
                <Select
                  {...SELECT_COMMON_PROPS}
                  value={attended}
                  onChange={(e) => onSetState(e, setAttended)}
                >
                  <option value={TYPE.YES} {...DEFAULT_OPTIONS_PROPS}>
                    {txtForm.willAttend[lang]}
                  </option>
                  <option value={TYPE.NO} {...DEFAULT_OPTIONS_PROPS}>
                    {txtForm.noAttend[lang]}
                  </option>
                </Select>
              </FormControl>
              {/* Form Sections - Partner */}
              {attended === TYPE.YES && ENABLE_PARTNER_MODE && (
                <>
                  <FormControl>
                    <FormLabel {...FORM_LABEL_PROPS}>{txtForm.willYouBringPartner[lang]}</FormLabel>
                    <Select
                      {...SELECT_COMMON_PROPS}
                      value={bringPartner}
                      onChange={(e) => onSetState(e, setBringPartner)}
                    >
                      <option value={1} style={{ color: 'black' }}>
                        1
                      </option>
                      {/* enable for more 2 guest */}
                      {!ENABLE_GUEST_PERSONALIZATION && (
                        <>
                          <option value={2} style={{ color: 'black' }}>
                            2
                          </option>
                        </>
                      )}
                      {ENABLE_GUEST_PERSONALIZATION && (
                        <>
                          {totalPartner > 1 && (
                            <option value={2} {...DEFAULT_OPTIONS_PROPS}>
                              2
                            </option>
                          )}
                          {totalPartner > 2 && (
                            <option value={3} {...DEFAULT_OPTIONS_PROPS}>
                              3
                            </option>
                          )}
                          {totalPartner > 3 && (
                            <option value={4} {...DEFAULT_OPTIONS_PROPS}>
                              4
                            </option>
                          )}
                          {totalPartner > 4 && (
                            <option value={5} {...DEFAULT_OPTIONS_PROPS}>
                              5
                            </option>
                          )}
                        </>
                      )}
                    </Select>
                    {/* remove `false` for enabling text partner informationn */}
                    {bringPartner === TYPE.YES && false && (
                      <FormHelperText color="mainColorText" fontSize="10px" fontStyle="italic">
                        *) Berdasarkan kondisi saat ini, satu tamu hanya boleh membawa 1 orang
                        partner atau pasangan dalam satu undangan. Terima kasih atas pengertiannya.
                      </FormHelperText>
                    )}
                  </FormControl>
                </>
              )}
              <Center>
                <Button
                  {...DEFAULT_BUTTON_PROPS}
                  color="btnMainColor"
                  fontWeight="normal"
                  isLoading={loading}
                  marginTop="24px"
                  size="sm"
                  type="button"
                  bgColor={BTN_COLOR}
                  // textTransform="uppercase"
                  onClick={onSubmitForm}
                >
                  {txtForm.submit[lang]}
                </Button>
              </Center>
              <Box pos={'relative'}>
                <BorderFrame isFooter h="36px" width="calc(100% + 48px)" left="-24px" />
              </Box>
            </Box>
          </Box>
        </Box>
      </WithAnimation>
    </Box>
  );
}

RSVPSection.propTypes = {
  lang: string,
};

RSVPSection.defaultProps = {
  lang: 'id',
};

export default React.memo(RSVPSection);
